import React, { Component } from 'react';
import homedata from '../../data/home.json';

class VideoPromo extends Component {
    render() {
        let videoPromoContent = homedata.videoPromo
        let publicUrl = process.env.PUBLIC_URL

        const inlineStyle = {
            backgroundImage: 'url(' + publicUrl + videoPromoContent.background + ')'
        }

        return <section id="video-promo" className="pt100 pb100 bg-parallax" style={inlineStyle}>

        </section>
    }
}


export default VideoPromo
