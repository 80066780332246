import React, { Component } from 'react';
import FooterData from '../../data/footerdata';

class Footer extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        // Calcular el año actual
        const currentYear = new Date().getFullYear();

        // Crear el texto de copyright dinámicamente
        const copyrightText = `© ${currentYear} Grupo Consultor PiBer. Todos los derechos reservados. `;

        return (
            <footer id="footer-section" className="pt50 pb50 bg-black">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 text-left">
                            <p className=" mt-3">Todos los derechos reservados © 2024. Teseo Data, 4to Creativo para Grupo Consultor PiBer.</p>
                        </div>
                        <div className="col-lg-4 text-left">
                            <h4>Contacto</h4>
                            <p><a href="https://www.facebook.com/share/BnJ6btoHwUhW6Acd/?mibextid=LQQJ4d"><i className={FooterData.socialicon[0].icon}></i> Facebook</a></p>
                            <p><a href="https://www.instagram.com/piber_grupoconsultor_s.c?igsh=MW56YWcxcTg4MzlrdQ=="><i className={FooterData.socialicon[5].icon}></i> Instagram</a></p>
                            <p>Teléfono: 771-406-0586</p>
                            <p>Av. Río de las Avenidas 606, Col. Periodistas, Pachuca, Hidalgo, México.</p>
                            <p>Correo: contacto@pibergrupoconsultor.com</p>
                        </div>
                        <div className="col-lg-4 text-left">
                            <h4>Secciones</h4>
                            <ul className="footer-links">
                                <li><a href="#">Inicio</a></li>
                                <li><a href="/#about">Acerca de</a></li>
                                <li><a href="/features">Servicios</a></li>
                                <li><a href="/portfolio">Portafolio</a></li>
                                <li><a href='https://pibergrupoconsultor.com/blog/author/admin/'>Blog</a></li>
                                <li><a href="/contact">Contacto</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row text-center mt-4">
                        <div className="col-sm-12" dangerouslySetInnerHTML={{ __html: copyrightText }}></div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
